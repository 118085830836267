input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.compress-col {
  margin: 0 0;
}
.compress-col > .col-md-1,
.compress-col > .col-md-2 {
  padding: 0 5px;
}

.form-control[readonly] {
  background-color: unset !important;
  color: #888888;
}

.card {
  margin: 0 0 10px 0;
}
div.form-row > div.col-md-1,
div.form-row > div.col-md-2,
div.form-row > div.col-md-3,
div.form-row > div.col-md-4,
div.form-row > div.col-md-5,
div.form-row > div.col-md-6,
div.form-row > div.col-md-7,
div.form-row > div.col-md-8,
div.form-row > div.col-md-9,
div.form-row > div.col-md-10,
div.form-row > div.col-md-11,
div.form-row > div.col-md-12,
div.form-row > div.col {
  padding: 0 5px;
}
